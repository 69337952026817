<template>
    <div class="slider">
        <Carousel :items-to-show="1" :settings="settings">
            <Slide v-for="(slider, index) in dataSource" :key="index">
                <div class="slide" :style='"background: url(/images/content/" + slider.img + ")"'>
                    <div class="cont-slider">
                        <h4>{{slider.cta}}</h4>
                        <p class="cta">{{slider.title}}</p>
                        <p><small>{{slider.short}}</small></p>
                        <a :href="'/' + flagSelected + '/' + slider.url" class="bt-primary">Conheça</a>
                    </div>
                </div>
            </Slide>
            
            <template #addons>
                <Pagination />
            </template>
        </Carousel>
    </div>
</template>


<script>
    import { defineComponent } from 'vue'
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Pagination, Slide } from 'vue3-carousel'

   import getMyFavouriteLanguage from "@/services/language.js";

    export default defineComponent({
        props: {
            dataSource: {}
        },

        components: {
            Carousel,
            Slide,
            Pagination,
        },
        
        data (){
            return {
                flagSelected: {},

                settings: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                    wrapAround: true,
                    autoplay: 7000,
                    transition: 1000
                },
            }
        },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
        },

        created() {
            this.flagUrl()
        }
    })
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_fonts.scss';
    @import '@/assets/scss/_colors.scss';

    .slider{
        .slide{
            position: relative;
            width: 100%;
            background-position: center !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;

            @media (max-width: 992px){
                height: 50vh;
                min-height: 400px;
            }

            @media (min-width: 992px){
                height: 90vh;
                min-height: 560px;
            }

            .cont-slider{
                position: absolute;
                left: 50%;
                top: 52%;
                transform: translate(-50%, -50%);
                z-index: 20;
                width: 100%;
                max-width: 800px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2rem 1rem;
                text-align: center;

                h4{
                    text-shadow: 0px 4px 6px rgba(0,0,0,0.5);

                    @media (max-width: 992px){
                        font-size: 18px;
                        line-height: 22px;
                        margin-bottom: 25px;
                    }
                    
                    @media (min-width: 992px){
                        font-size: 40px;
                        line-height: 44px;
                        margin-bottom: 30px;
                    }
                }
                
                .cta{
                    font-family: $font-primary-light;
                    color: $color-white;
                    text-transform: uppercase;
                    text-shadow: 0px 4px 6px rgba(0,0,0,0.5);
                    
                    @media (max-width: 992px){
                        font-size: 18px;
                        line-height: 22px;
                        margin-bottom: 25px;
                    }
                    
                    @media (min-width: 992px){
                        font-size: 40px;
                        line-height: 44px;
                        margin-bottom: 30px;
                    }
                }

                p{
                    color: $color-white;
                    text-shadow: 0px 4px 6px rgba(0,0,0,0.5);

                    small{
                        @media (max-width: 992px){
                            font-size: 13px;
                            line-height: 16px;
                        }
                        
                        @media (min-width: 992px){
                            font-size: 22px;
                            line-height: 26px;
                        }
                    }
                }

                .bt-primary{
                    @media (max-width: 1200px){
                        margin-top: 15px;
                    }
                    
                    @media (min-width: 1200px){
                        margin-top: 25px;
                    }
                }
            }
        }


        .nav-slider{
            position: absolute;
            z-index: 30;
            left: 50%;
            bottom: 10px;
            transform: translateX(-50%);
            display: flex;
            align-items: center;

            li{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                cursor: pointer;

                &:after{
                    content: '';
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: $color-secondary;
                    transition: .3s;
                }

                &.active{
                    &:after{
                        width: 18px;
                        height: 18px;
                        background: $color-primary;
                    }
                }

                @media (min-width: 992px){
                    &:hover{
                        &:after{
                            background: $color-primary;
                        }
                    }
                }
            }
        }
    }
</style>

<style lang="scss">
    @import '@/assets/scss/_colors.scss';
    
    .slider{
        .carousel__slide:after{
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.5);
        }

        .carousel__pagination{
            position: absolute;
            z-index: 30;
            left: 50%;
            bottom: 10px;
            padding: 0;
            margin: 0;
            transform: translateX(-50%);
            display: flex;
            align-items: center;

            .carousel__pagination-button{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                margin: 0;
                background: none;
                cursor: pointer;

                &:after{
                    content: '';
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background: $color-secondary;
                    transition: .3s;
                }

                &.carousel__pagination-button--active{
                    &:after{
                        width: 18px;
                        height: 18px;
                        background: $color-primary;
                    }
                }

                @media (min-width: 992px){
                    &:hover{
                        &:after{
                            background: $color-primary;
                        }
                    }
                }
            }
        }
    } 
</style>