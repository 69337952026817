<template>
    <section class="team emphasis">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="title-primary">
                        <span>Equipa</span>
                        <small>Conheça nossos profissionais</small>
                    </h2>

                    <div class="professionals">
                        <div class="professional" v-for="(list,indexTeam) in teamZenith" :key="indexTeam" @click="modalCurriculum(list)">
                            <span class="photo">
                                <span class="circle">
                                    <img :src='"/images/content/" + list.img' :alt="list.name">
                                </span>
                            </span>
                            <h6>{{list.name}}</h6>
                            <small class="office">{{list.office}}</small>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <Carousel :items-to-show="1" :settings="settings" :breakpoints="breakpoints" :if="breakpoints.aaa">
                <Slide v-for="(slider, index) in teamZenith" :key="index">
                    <div class="professional" @click="modalCurriculum(slider)">
                        <span class="photo">
                            <span class="circle">
                                <img :src='"/images/content/" + slider.img' :alt="slider.name">
                            </span>
                        </span>
                        <h6>{{slider.name}}</h6>
                        <small class="office">{{slider.office}}</small>
                    </div>
                </Slide>
                
                <template #addons>
                    <Navigation />
                </template>
            </Carousel> -->

            <!-- <div class="row">
                <a href="#" class="bt-secondary">Conheça a todos</a>
            </div> -->
        </div>
    </section>


    <!-- MODAL -->
    <div class="modal modal-team" v-if="modalTeam.modal">
        <div class="modal-cont">
            <div class="modal-close" @click="modalTeam.modal = false"></div>
            <div class="modal-scroll">
                <div class="professional">
                    <div class="photo">
                        <div class="circle">
                            <img :src="'/images/content/' + modalTeam.content.img" :alt="modalTeam.content.name">
                        </div>
                    </div>

                    <h3 class="title-secondary">{{modalTeam.content.name}}</h3>
                </div>

                <h4 class="subtitle">{{modalTeam.content.office}}</h4>
                <div v-html="modalTeam.content.curriculum"></div>
            </div>
        </div>
    </div>
</template>


<script>
    import { defineComponent } from 'vue'
    // import 'vue3-carousel/dist/carousel.css'
    // import { Carousel, Navigation, Slide } from 'vue3-carousel'

    import team_en from "@/contents/teamZenith-pt.json"
    import team_es from "@/contents/teamZenith-pt.json"
    import team_fr from "@/contents/teamZenith-pt.json"
    import team_pt from "@/contents/teamZenith-pt.json"

    export default defineComponent({
        components: {
            // Carousel,
            // Slide,
            // Navigation,
        },
        
        data (){
            return {
                settings: {
                itemsToShow: 1,
                snapAlign: 'center',
                wrapAround: true,
                autoplay: 5000,
                transition: 1000
                },

                breakpoints: {               
                700: {
                    itemsToShow: 3
                }
                },

                modalTeam:{
                    modal: false,
                    content: {}
                },

                teamZenith: []
            }
        },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            modalCurriculum(value){
                this.modalTeam.content = value
                this.modalTeam.modal = true
            },

            languageContent(){
                if ( this.flagSelected === 'en' ){
                    this.teamZenith = team_en
                } else if ( this.flagSelected === 'es' ){
                    this.teamZenith = team_es
                } else if ( this.flagSelected === 'fr' ){
                    this.teamZenith = team_fr
                } else {
                    this.teamZenith = team_pt
                }
            },
        },

        created() {
            this.flagUrl()
            this.languageContent()
        }
   })
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_elements.scss';
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_modal.scss';

    .team{
        @include paddingSection;

        @media (min-width: 700px){
            padding-left: 15px;
            padding-right: 15px;
        }

        .professionals{
            display: flex;
            justify-content: center;
        }

        .professional{
            width: 100%;
            padding: 0 15px;
            text-align: center;

            @media (max-width: 700px){
                max-width: 260px;
            }
            
            @media (min-width: 700px){
                max-width: 300px;
            }

            .photo{
                @include shadownMedium;
                border: 2px solid $color-white;
                margin-bottom: 30px;
                padding: 10px;
                border-radius: 50%;
                display: block;
                width: 100%;
                transition: .3s ease-in-out;

                .circle{
                    display: block;
                    width: 100%;
                    padding-top: 100%;
                    position: relative;
                    border-radius: 50%;
                    overflow: hidden;

                    img{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 104%;
                        transform: translate(-50%, -50%);
                        transition: .5s ease-in-out;
                    }
                }
            }

            h6{
                font-size: 18px;
                line-height: 20px;
                margin-bottom: 5px;
            }

            .office{
                @media (max-width: 576px){
                    font-size: 11px;
                    line-height: 13px;
                }

                @media (min-width: 576px){
                    font-size: 13px;
                    line-height: 15px;
                }
            }


            @media (min-width: 992px){
                cursor: pointer;

                &:hover{
                    .photo{
                        padding: 5px;
                    }
                }
            }
        }


        .bt-secondary{
            @media (max-width: 576px){
                margin: 40px auto 0;
            }
            
            @media (min-width: 576px){
                margin: 60px auto 0;
            }
        }
    }


    .modal-team{
        .professional{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .photo{
                border: 1px solid $color-secondary;
                margin-bottom: 20px;
                padding: 10px;
                border-radius: 50%;
                display: block;
                width: 100%;
                max-width: 300px;
                transition: .3s ease-in-out;

                .circle{
                    display: block;
                    width: 100%;
                    padding-top: 100%;
                    position: relative;
                    border-radius: 50%;
                    border: 1px solid #eee;
                    overflow: hidden;

                    img{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 100%;
                        transform: translate(-50%, -50%);
                        transition: .5s ease-in-out;
                    }
                }
            }

            .title-secondary{
                margin-bottom: 40px;
            }
        }
    }
</style>