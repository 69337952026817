// import vue from 'vue'
import { createStore } from 'vuex'
// import axios from 'axios'
// import api from '@/config/api.js'

export default createStore({
   state: {
      allLanguages: '',
      SelectLanguage: {
         code: 'en',
         flag: '',
         language: ''
      },

      profile: {},
      statusLogin: false,
      phaseCurrent: '',

      // cart: [
      //    {
      //       url: "",
      //       thumbs: "",
      //       name: "",
      //       brand: "",
      //       store: "",
      //       price: ""
      //    }
      // ]
   },
   
   mutations: {
      allLanguages(state, value){
         state.allLanguages = value
      },
      preLanguage(state,code){
         state.SelectLanguage.code = code
      },
      preLanguageFlag(state,flag){
         state.SelectLanguage.flag = flag
      },
      preLanguageName(state,language){
         state.SelectLanguage.language = language
      },
      changeLanguage(state, value){
         state.SelectLanguage = value
      },
      errorLogin(state, value){
         state.statusLogin = value
      },
      profile(state, value){
         state.profile = value
      },
      loginStatus(state, value){
         state.statusLogin = value
      },
      validatePhoneNumber(state, value){
         state.profile.profile_phone_number_verified = value
      },
      phaseCurrent(state, value){
         state.phaseCurrent = value
      },
      // minicart(state, value){
      //    state.cart = value
      // },
   },
   actions: {
      // addCart ({ item }) {
      //    item.commit('minicart')
      // }

      // test ({commit}){
      //    return axios.get(api('api/v1/platform/language')).then((result) => {
      //       commit('changeLanguage',result.data)
      //    })
      // }
        
   },
   modules: {
      
   },
   getters: {
      
   }
})
