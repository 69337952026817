<template>
    <div class="content-page no-schedule">
        <Header :dataSource="schedule" />

        <!-- <div class="image-header">
            <h1 class="title-primary"><span>Contacto</span></h1>
            <img src="/images/content/contacto-header.jpg">
        </div> -->
        
        
        <section class="contacto">
            <div class="container">
                <div class="load" v-if="flowSend.load">
                    <img src="~@/assets/images/icons/loading.svg">
                </div>

                <!-- SUCCESS -->
                <div class="row" v-if="flowSend.status == 'success' && !flowSend.load">
                    <div class="col-12">
                        <h2 class="title-primary">
                            <span>Sua mensagem já chegou!</span>
                            <small>Em breve vamos retornar seu contacto.</small>
                        </h2>
                    </div>
                </div>
                

                <!-- ERROR -->
                <div class="row" v-if="flowSend.status == 'error' && !flowSend.load">
                    <div class="col-12">
                    <h2 class="title-primary">
                        <span>Houve um erro</span>
                        <small>Por favor tente agendar sua avaliação daqui alguns minutos</small>
                    </h2>
                    </div>
                </div>


                <!-- FORM -->
                <div class="row" v-if="!flowSend.status && !flowSend.load">
                    <div class="col-12">
                        <h2 class="title-primary">
                            <span>Contacto</span>
                            <small>Preencha o formulário abaixo</small>
                        </h2>
                    </div>

                    <div class="col-12 col-lg-6">
                        <input type="text" v-model="formContact.name" @blur="errors.name = false" class="form" :class="{error: errors.name}" :placeholder="getMyLanguage('Footer','schedule.lbl.name')">
                    </div>

                    <div class="col-12 col-lg-6">
                        <input type="email" inputmode="email" v-model="formContact.email" @blur='regexEmail()' class="form" :class="{error: errors.email || !validateEmail.regex}" :placeholder="getMyLanguage('Footer','schedule.lbl.email')">
                    </div>

                    <div class="col-12 col-lg-6">
                        <input type="tel" inputmode="tel" v-model="formContact.phone_number" @blur="errors.phone_number = false" class="form" :class="{error: errors.phone_number}" :placeholder="getMyLanguage('Footer','schedule.lbl.phone')">
                    </div>
                    
                    <div class="col-12 col-lg-6">
                        <input type="text" v-model="formContact.subject" @blur="errors.subject = false" class="form" :class="{error: errors.subject}" :placeholder="getMyLanguage('Contacto','form.lbl.subject')">
                    </div>

                    <div class="col-12">
                        <textarea v-model="formContact.message" @blur="errors.message = false" class="form" :class="{error: errors.message}" :placeholder="getMyLanguage('Contacto','form.lbl.msg')"></textarea>
                    </div>

                    <div class="col-12 col-bt-send">
                        <div class="bt-secondary" @click="checkForm()">{{ getMyLanguage("Footer","schedule.btn-send") }}</div>
                    </div>
                </div>
            </div>
        </section>

        <FooterShort />
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Header.vue";
    import FooterShort from "@/components/FooterShort.vue";
    
    import getMyFavouriteLanguage from "@/services/language.js";

    import apiMarket from '@/config/apiMarket.js'

    export default {
        components: {
            Header,
            FooterShort
        },

        data() {
            return {
                flagSelected: {},
                schedule: false,
                dataCountry: [],

                errors: {},
               
                validateEmail: {
                    regex: true
                },
                
                formContact: {
                    phone_country_id: "acc8b9e4-d63b-442f-b2d3-ed5593e6459f",
                    store_reference: process.env.VUE_APP_STORE_REFERENCE,
                    message_template_id: process.env.VUE_APP_MESSAGE_TEMPLATE_ID,
                    
                    custom_fields: []
                },
                
                flowSend: {
                    load: false,
                    status: ""
                }
            }
        },

        async mounted() {
            // await apiMarket.get('api/v1/platform/country')
            // .then(response => {
            //     this.dataCountry = response.data
            //     this.formContact.phone_number_country_id = this.dataCountry.filter(country => country.isoCode.toLowerCase() == this.flagSelected)[0].id
            // })
            // .catch(error => {
            //     console.log(error)
            //     this.formContact.phone_number_country_id = "acc8b9e4-d63b-442f-b2d3-ed5593e6459f"
            // })
        },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            getMyLanguage(group, code) {
                return getMyFavouriteLanguage(group, code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            regexEmail(){
               const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

               if (regex.test(this.formContact.email)) {
                  this.errors.email = false
                  this.validateEmail.regex = true
               } else{
                  this.errors.email = true
                  this.validateEmail.regex = false
               }
            },

            checkForm(){
               if ( !this.formContact.name ){ this.errors.name = true } else { this.errors.name = false }
               if ( !this.formContact.email ){ this.errors.email = true } else { this.errors.email = false }
               if ( !this.formContact.phone_number ){ this.errors.phone_number = true } else { this.errors.phone_number = false }
               if ( !this.formContact.subject ){ this.errors.subject = true } else { this.errors.subject = false }
               if ( !this.formContact.message ){ this.errors.message = true } else { this.errors.message = false }
               if ( !this.errors.name && !this.errors.email && this.validateEmail.regex && !this.errors.phone_number && !this.errors.subject && !this.errors.message ){
                  this.sendSchedule()
               }
            },

            async sendSchedule(){
               this.flowSend.load = true

                apiMarket.post('/api/v1/messaging/contact/send', this.formContact)
                .then(response => {
                    setTimeout(() => {
                        this.flowSend.load = false
                        this.flowSend.status = "success"
                        console.log(response.data)
                    }, 2000);
                })
                .catch(error => {
                    setTimeout(() => {
                        this.flowSend.load = false
                        this.flowSend.status = "error"
                        console.log(error)
                    }, 2000);
                })

            }
        },

        created() {
            this.flagUrl()
            this.goTop()
        }
    };
</script>

<style src="@/assets/scss/_content.scss" lang="scss" scoped />
<style src="./custom-contacto.scss" lang="scss" scoped />