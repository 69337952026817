<template>
    <section class="products emphasis">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="title-primary">
                        <span>{{dataSource.infos.title}}</span>
                        <small>{{dataSource.infos.short}}</small>
                    </h2>
                </div>
            </div>

            <Carousel :items-to-show="1" :settings="settings" :breakpoints="breakpoints" :if="breakpoints.aaa">
                <Slide v-for="(slider, index) in dataSource.products" :key="index">
                    <!-- <a :href="slider.link" class="product"> -->
                    <a :href='"/" + flagSelected + "/nome-do-produto"' class="product">
                        <span class="photo">
                            <img :src='"/images/content/" + slider.img' :alt="slider.name">
                        </span>
                        <h4>{{slider.name}}</h4>
                    </a>
                </Slide>
                
                <template #addons>
                    <Navigation />
                </template>
            </Carousel>
        </div>
    </section>
</template>


<script>
    import { defineComponent } from 'vue'
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Navigation, Slide } from 'vue3-carousel'
    
    export default defineComponent({
        props: {
            dataSource: {}
        },

        components: {
            Carousel,
            Slide,
            Navigation,
        },
        
        data (){
            return {
                flagSelected: {},
                
                settings: {
                itemsToShow: 1,
                snapAlign: 'center',
                wrapAround: true,
                autoplay: 5000,
                transition: 1000
                },

                breakpoints: {               
                    700: {
                        itemsToShow: 3
                    }
                },
            }
        },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            }
        },

        created (){
            this.flagUrl()
        }
    })
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_elements.scss';
    @import '@/assets/scss/_carousel.scss';

    .products{
        @include paddingSection;

        @media (min-width: 700px){
            padding-left: 15px;
            padding-right: 15px;
        }

        .product{
            width: 100%;
            padding: 0 15px;

            .photo{
                display: block;
                border: 1px solid $color-secondary;
                margin-bottom: 10px;
                overflow: hidden;
                width: 100%;
                padding-top: 56.25%;
                position: relative;

                img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: auto;
                    height: auto;
                    max-width: 200%;
                    min-width: 100%;
                    transform: translate(-50%, -50%);
                    transition: .5s ease-in-out;
                }
            }

            h4{
                text-align: center;
                margin-bottom: 30px;

                @media (max-width: 576px){
                    font-size: 14px;
                    line-height: 16px;
                }
                
                @media (min-width: 576px){
                    font-size: 16px;
                    line-height: 18px;
                }
            }

            @media (min-width: 992px){
                &:hover{
                    .photo{
                        img{
                            filter: brightness(1.2);
                        }
                    }
                }
            }
        }
    }
</style>