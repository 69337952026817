<template>
    <CookieConsent
        :message= texts.message
        :link-label= texts.linkLabel
        :buttonLabel= texts.btnLabel
        :href= urlPrivacy
    />
</template>

<script>
    import CookieConsent from 'vue-cookieconsent-component/src/components/CookieConsent.vue'
    import getMyFavouriteLanguage from "@/services/language.js";

    export default {
        components: {
            CookieConsent
        },

        data (){
            return{
                language: this.$store.state.SelectLanguage.code,
                urlPrivacy: '',
                texts: {
                    message: '',
                    linkLabel: '',
                    btnLabel: ''
                }
            }
        },

        methods: {
            privacyUrl(){
                this.urlPrivacy = "/" + this.language + "/privacy"
            },

            getMyLanguage(group, code){
               return getMyFavouriteLanguage(group, code)
            },

            cookieTexts(){
                let textsMessage = this.getMyLanguage("CookieConsent", "cookie.consent.message")
                let textsLinkLabel = this.getMyLanguage("CookieConsent", "cookie.consent.link.label")
                let textsBtnLabel = this.getMyLanguage("CookieConsent", "cookie.consent.btn.label")

                this.texts.message = textsMessage
                this.texts.linkLabel = textsLinkLabel
                this.texts.btnLabel = textsBtnLabel
            }
        },

        created() {
            this.privacyUrl();
            this.cookieTexts()
        }
    }
</script>



<style lang="scss">
    @import '@/assets/scss/_colors.scss';
    @import '@/assets/scss/_fonts.scss';
    @import '@/assets/scss/_elements.scss';


    .cookie-consent{
        @include shadown;
        
        position: fixed;
        z-index: 99999;
        left: 5px;
        bottom: 5px;
        padding: 18px;
        border: 2px solid rgba($color: $color-white, $alpha: 0.25);
        text-align: center;
        border-radius: 16px;
        background: $color-primary;
        color: $color-white;

        .cookie-consent-message{
            display: block;
            font-size: 22px;
            font-weight: 600;
            margin-bottom: 30px;

            .cookie-consent-link{
                display: none !important;
                
                font-size: 14px;
                line-height: 30px;
                font-weight: 400;
                padding: 0 10px;
                border: 1px solid rgba($color: $color-white, $alpha: 0.3);
                color: $color-white;
                border-radius: 15px;
                display: block;
                margin-top: 10px;
            }
        }
        
        .cookie-consent-compliance{
            background: $color-secondary;
            color: $color-white;
            display: block;
            white-space: nowrap;
            border-radius: 12px;
            transition: .3s;
            border: none;
            line-height: 40px;
            height: 40px;
            font-weight: 600;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;

            @media (max-width: 576px){
                font-size: 14px;
                padding: 0 15px;
            }
        
            @media (min-width: 576px){
                font-size: 16px;
                padding: 0 20px;
            }
        }
    }
</style>