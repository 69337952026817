<template>

   <section class="schedule">
      <div id="agendar-avaliacao" class="anc"></div>
      
      <div class="container">
         <div class="load" v-if="flowSend.load">
            <img src="~@/assets/images/icons/loading.svg">
         </div>

         <!-- SUCCESS -->
         <div class="row" v-if="flowSend.status == 'success' && !flowSend.load">
            <div class="col-12">
               <h2 class="title-primary">
                  <span>Sua mensagem já chegou!</span>
                  <small>Em breve vamos confirmar a sua avaliação.</small>
               </h2>

               <div class="form-success">
                  <p>A nossa equipa está a rever as suas informações e entrará em contacto em breve para confirmar a data e a hora da sua avaliação.</p>
                  <p><em>Agradecemos por escolher a <strong>Zenith Saúde</strong></em></p>
               </div>
            </div>
         </div>
         

         <!-- ERROR -->
         <div class="row" v-if="flowSend.status == 'error' && !flowSend.load">
            <div class="col-12">
               <h2 class="title-primary">
                  <span>Houve um erro</span>
                  <small>Por favor tente agendar sua avaliação daqui alguns minutos</small>
               </h2>
            </div>
         </div>


         <!-- FORM -->
         <div class="row" v-if="!flowSend.status && !flowSend.load">
            <div class="col-12">
               <h2 class="title-primary">
                  <span>{{ getMyLanguage("Footer","schedule.title") }}</span>
                  <small>{{ getMyLanguage("Footer","schedule.subtitle") }}</small>
               </h2>
            </div>

            <div class="col-12 col-lg-6">
               <input type="text" v-model="formSchedule.name" @blur="errors.name = false" class="form" :class="{error: errors.name}" :placeholder="getMyLanguage('Footer','schedule.lbl.name')">
            </div>

            <div class="col-12 col-lg-6">
               <input type="email" inputmode="email" v-model="formSchedule.email" @blur='regexEmail()' class="form" :class="{error: errors.email || !validateEmail.regex}" :placeholder="getMyLanguage('Footer','schedule.lbl.email')">
               <!-- <p class="msg-error" v-if="!validateEmail.regex">E-mail inválido</p> -->
            </div>

            <div class="col-12 col-lg-6">
               <input type="tel" inputmode="tel" v-model="formSchedule.phone_number" @blur="errors.phone_number = false" class="form" :class="{error: errors.phone_number}" :placeholder="getMyLanguage('Footer','schedule.lbl.phone')">
            </div>

            <div class="col-12 col-lg-6">
               <div class="time">
                  <div class="col-date" :class="{errorDate: errors.date}">
                     <span>Melhor data: </span>
                     <input type="date" inputmode="tel" v-model="flowSend.date" @change="errors.date = false" class="form">
                  </div>

                  <div class="col-period">
                     <div class="form-group-radio">
                        <input type="radio" id="morning" name="period" class="form-radio" value="Manhã" v-model="formSchedule.custom_fields[1].value" :checked="formSchedule.custom_fields[1].value == 'Manhã'">
                        <label for="morning" class="form-check-label">{{ getMyLanguage("Footer","schedule.lbl.morning") }}</label>
                     </div>
                     
                     <div class="form-group-radio">
                        <input type="radio" id="afternoon" name="period" class="form-radio" value="Tarde" v-model="formSchedule.custom_fields[1].value" :checked="formSchedule.custom_fields[1].value == 'Tarde'">
                        <label for="afternoon" class="form-check-label">{{ getMyLanguage("Footer","schedule.lbl.afternoon") }}</label>
                     </div>
                  </div>
               </div>
            </div>

            <div class="col-12">
               <textarea v-model="formSchedule.message" @blur="errors.message = false" class="form" :class="{error: errors.message}" :placeholder="getMyLanguage('Footer','schedule.lbl.what-to-evaluate')"></textarea>
            </div>

            <div class="col-12 col-bt-send">
               <div class="bt-secondary" @click="checkForm()">{{ getMyLanguage("Footer","schedule.btn-send") }}</div>
            </div>
         </div>
      </div>
   </section>

   <footer>
      <div class="container-fluid">
         <div class="row content">
            <div class="container">
               <div class="row">
                  <div class="col-12 col-sm-6 col-lg-3 col-logo-footer">
                     <img src="~@/assets/images/layout/logo-zenith-saude-white.svg" alt="Zenith Saúde">
                  </div>

                  <div class="col-12 col-sm-6 col-lg-4 col-contact">
                     <ContactUs />
                  </div>
               </div>
            </div>
         </div>

         <div class="row copyright">
            <div class="container">
               <div class="row">
                  <div class="col-6 col-md-4 col-company">
                     <p>Zenith Saúde Ltd<br>NIPC: 517362813</p>
                  </div>

                  <div class="col-12 col-md-4 col-copyright">
                     <p>Copyright © 2023 | Zenith Saúde<br>{{ getMyLanguage("Footer","contacts.all-rights-reserved") }}</p>
                  </div>

                  <div class="col-6 col-md-4 col-developed">
                     <a href="https://indiceavancado.com/pt" target="_blank"></a>
                  </div>
               </div>
            </div>
         </div>

      </div>
   </footer>
</template>


<script>
   import ContactUs from "@/components/ContactUs"

   import getMyFavouriteLanguage from "@/services/language.js";

   import moment from 'moment';

   import apiMarket from '@/config/apiMarket.js'

    export default{
         components: {
            ContactUs
         },

         props: {
            dataSource: {}
         },

         data() {
            return {
               errors: {},
               
               validateEmail: {
                  regex: true
               },
               
               formSchedule: {
                  phone_country_id: "acc8b9e4-d63b-442f-b2d3-ed5593e6459f",
                  store_reference: process.env.VUE_APP_STORE_REFERENCE,
                  message_template_id: process.env.VUE_APP_MESSAGE_TEMPLATE_ID,

                  custom_fields: [
                     {
                        key: "Data",
                        value: ""
                     },
                     {
                        key: "Período",
                        value: "Manhã"
                     },
                     {
                        key: "Origem",
                        value: ""
                     }
                  ],
                  subject: "Agendamento pelo site"
               },
               
               flowSend: {
                  date: "",
                  load: false,
                  status: ""
               }
            }
         },

         async mounted() {
            this.formSchedule.custom_fields[2].value = this.dataSource
         },

         methods: {
            getMyLanguage(group, code){
               return getMyFavouriteLanguage(group, code)
            },

            dateTime(value) {
                return moment(value).utc().format('ll');
            },

            regexEmail(){
               const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

               if (regex.test(this.formSchedule.email)) {
                  this.errors.email = false
                  this.validateEmail.regex = true
               } else{
                  this.errors.email = true
                  this.validateEmail.regex = false
               }
            },

            checkForm(){
               this.formSchedule.custom_fields[0].value = this.dateTime(this.flowSend.date)
               
               if ( !this.formSchedule.name ){ this.errors.name = true } else { this.errors.name = false }
               if ( !this.formSchedule.email ){ this.errors.email = true } else { this.errors.email = false }
               if ( !this.formSchedule.phone_number ){ this.errors.phone_number = true } else { this.errors.phone_number = false }
               if ( !this.flowSend.date ){ this.errors.date = true } else { this.errors.date = false }
               if ( !this.formSchedule.message ){ this.errors.message = true } else { this.errors.message = false }
               if ( !this.errors.name && !this.errors.email && this.validateEmail.regex && !this.errors.phone_number && !this.errors.date && !this.errors.message ){
                  this.sendSchedule()
               }
            },

            sendSchedule(){
               this.flowSend.load = true

               apiMarket.post('/api/v1/messaging/contact/send', this.formSchedule)
                .then(response => {
                    setTimeout(() => {
                        this.flowSend.load = false
                        this.flowSend.status = "success"
                        console.log(response.data)
                    }, 2000);
                })
                .catch(error => {
                    setTimeout(() => {
                        this.flowSend.load = false
                        this.flowSend.status = "error"
                        console.log(error)
                    }, 2000);
                })
            }
         }
    }
</script>

<style src="@/assets/scss/_footer.scss" lang="scss" scoped />