<template>
    <div class="content-page not-found">
        <Header />

        <section class="erro404">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h1 class="title-primary"><span>{{ this.getMyLanguage("error", "error.404") }}</span></h1>
                    </div>
                    <div class="col-12 img-404">
                        <img src="~@/assets/images/icons/404.svg" alt="404">
                    </div>
                </div>
            </div>
        </section>

        <Footer dataSource="Página não encontrada" />
    </div>
</template>


<script>
    const $ = require('jquery')
    window.$ = $

    import Header from '@/components/Header.vue';
    import Footer from '@/components/Footer.vue';
    
    import getMyFavouriteLanguage from "@/services/language.js";

    export default {
        components: {
            Header,
            Footer
        },

        data() {
            return {
                schedule: false
            }
        },

        async mounted() {
            this.flagUrl()
            this.goTop()
        },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },
        },

        created () {
            document.title = this.getMyLanguage("seo","title.404");
            document.head.querySelector('meta[name=description]').content = ''
            document.head.querySelector('meta[name=robots]').content = 'noindex, nofollow'
        },
   }
</script>

<style src="./custom-404.scss" lang="scss" scoped />