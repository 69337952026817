<template>
    <div class="content-page featured-image">
        <Header />

        <div class="image-header">
            <h1 class="title-primary">
                <span>Medicina Estética</span>
                <small>Experimente a medicina estética avançada no Zenith Saúde e descubra uma abordagem única para realçar sua beleza interior.</small>
            </h1>

            <img src="/images/content/medicina-estetica-header.jpg">
        </div>
        
        
        <section class="medicina-estetica">
            <div class="container">

                <div class="row list">
                    
                    <!-- BIOESTIMULADORES DE COLAGÉNIO -->
                    <div class="col-12" v-if="filterBioEstimuladoresColagenio.length">
                        <h2 class="title-primary title-left">
                            <span>Bioestimuladores de colagénio</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/tratamento/' + treatment.slug" v-for="(treatment,index) in filterBioEstimuladoresColagenio" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + treatment.img">
                        </span>
                        <h2 class="title">{{treatment.name}}</h2>
                        <p>{{treatment.cta}}</p>
                    </a>
                    
                    
                    
                    <div class="bar-category" v-if="filterPreenchedores.length"></div>                    

                    <!-- PREENCHEDORES -->
                    <div class="col-12" v-if="filterPreenchedores.length">
                        <h2 class="title-primary title-left">
                            <span>Preenchedores</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/tratamento/' + treatment.slug" v-for="(treatment,index) in filterPreenchedores" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + treatment.img">
                        </span>
                        <h2 class="title">{{treatment.name}}</h2>
                        <p>{{treatment.cta}}</p>
                    </a>
                    
                    
                    
                    <div class="bar-category" v-if="FilterReestruturantesDerme.length"></div>                    

                    <!-- REESTRUTURANTES DE DERME -->
                    <div class="col-12" v-if="FilterReestruturantesDerme.length">
                        <h2 class="title-primary title-left">
                            <span>Reestruturantes de derme</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/tratamento/' + treatment.slug" v-for="(treatment,index) in FilterReestruturantesDerme" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + treatment.img">
                        </span>
                        <h2 class="title">{{treatment.name}}</h2>
                        <p>{{treatment.cta}}</p>
                    </a>

                    
                    
                    <div class="bar-category" v-if="FilterToxinaBotulinica.length"></div>                    
                    
                    <!-- TOXINA BOTULÍNICA -->
                    <div class="col-12" v-if="FilterToxinaBotulinica.length">
                        <h2 class="title-primary title-left">
                            <span>Toxina Botulínica</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/tratamento/' + treatment.slug" v-for="(treatment,index) in FilterToxinaBotulinica" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + treatment.img">
                        </span>
                        <h2 class="title">{{treatment.name}}</h2>
                        <p>{{treatment.cta}}</p>
                    </a>

                </div>
            </div>
        </section>

        <Footer dataSource="Tratamentos de Medicina estética" />
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";
    
    import getMyFavouriteLanguage from "@/services/language.js";

    import treatments_en from "@/contents/treatments-pt.json"
    import treatments_es from "@/contents/treatments-pt.json"
    import treatments_fr from "@/contents/treatments-pt.json"
    import treatments_pt from "@/contents/treatments-pt.json"

    export default {
        components: {
            Header,
            Footer
        },
        
        data() {
            return {
                flagSelected: {},
            }
        },

        // async mounted() {

        // },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            getMyLanguage(group, code) {
                return getMyFavouriteLanguage(group, code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            languageContent(){
                if ( this.flagSelected === 'en' ){
                    this.treatments = treatments_en
                } else if ( this.flagSelected === 'es' ){
                    this.treatments = treatments_es
                } else if ( this.flagSelected === 'fr' ){
                    this.treatments = treatments_fr
                } else {
                    this.treatments = treatments_pt
                }
            },
        },

        computed: {
            filterBioEstimuladoresColagenio() {
                if (this.treatments.length === 0) {
                return [];
                }

                return this.treatments.filter(treatment => treatment.tag === 'Bioestimuladores de colagénio');
            },

            filterPreenchedores() {
                if (this.treatments.length === 0) {
                return [];
                }

                return this.treatments.filter(treatment => treatment.tag === 'Preenchedores');
            },

            FilterReestruturantesDerme() {
                if (this.treatments.length === 0) {
                return [];
                }

                return this.treatments.filter(treatment => treatment.tag === 'Reestruturantes de derme');
            },

            FilterToxinaBotulinica() {
                if (this.treatments.length === 0) {
                return [];
                }

                return this.treatments.filter(treatment => treatment.tag === 'Toxina Botulínica');
            }
        },

        created() {
            this.flagUrl()
            this.languageContent()
            this.goTop()

            document.title = "Medicina Estética | Zenith Saúde"
            document.head.querySelector("meta[name=description]").content = "Experimente a medicina estética avançada no Zenith Saúde e descubra uma abordagem única para realçar sua beleza interior.";
        }
    };
</script>

<style src="@/assets/scss/_content.scss" lang="scss" scoped />
<style src="./custom-medicina-estetica.scss" lang="scss" scoped />