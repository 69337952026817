<template>
    <footer>
      <div class="container-fluid">
         <div class="row content">
            <div class="container">
               <div class="row">
                  <div class="col-12 col-sm-6 col-lg-3 col-logo-footer">
                     <img src="~@/assets/images/layout/logo-zenith-saude-white.svg" alt="Zenith Saúde">
                  </div>

                  <div class="col-12 col-sm-6 col-lg-4 col-contact">
                     <ContactUs />
                  </div>
               </div>
            </div>
         </div>

         <div class="row copyright">
            <div class="container">
               <div class="row">
                  <div class="col-6 col-md-4 col-company">
                     <p>Zenith Saúde Ltd<br>NIPC: 517362813</p>
                  </div>

                  <div class="col-12 col-md-4 col-copyright">
                     <p>Copyright © 2023 | Zenith Saúde<br>{{ getMyLanguage("Footer","contacts.all-rights-reserved") }}</p>
                  </div>

                  <div class="col-6 col-md-4 col-developed">
                     <a href="https://indiceavancado.com/pt" target="_blank"></a>
                  </div>
               </div>
            </div>
         </div>

      </div>
   </footer>
</template>


<script>
   import ContactUs from "@/components/ContactUs"

   import getMyFavouriteLanguage from "@/services/language.js";

    export default{
         components: {
            ContactUs
         },

         methods: {
            getMyLanguage(group, code){
               return getMyFavouriteLanguage(group, code)
            }
         }
    }
</script>

<style src="@/assets/scss/_footer.scss" lang="scss" scoped />