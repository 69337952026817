<template>
    <div class="contact-us">
        <div class="social">
            <a href="#" class="facebook" target="_blank"></a>
            <a href="#" class="instagram" target="_blank"></a>
            <a href="#" class="linkedin" target="_blank"></a>
            <a href="#" class="twitter" target="_blank"></a>
            <a href="#" class="youtube" target="_blank"></a>
        </div>

        <div class="contacts">
            <a href="tel:351222410061">+351 222 410 061 <small>{{ getMyLanguage("Footer","contacts.fixed-network") }}</small></a>
            <a href="tel:351932080680">+351 932 080 680 <small>{{ getMyLanguage("Footer","contacts.mobile-network") }}</small></a>
        </div>

        <div class="address">
            <p>Rua Nossa Senhora do Amparo, 151, 4435-350 | Rio Tinto | Porto | Portugal</p>
            <a href=""><small>{{ getMyLanguage("Footer","contacts.btn-map") }}</small></a>
        </div>
    </div>
</template>

<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    export default {
        methods: {
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';

    .contact-us{
        position: relative;
        z-index: 20;

        .social{
            display: flex;
            margin-bottom: 20px;
    
            a{
                display: block;
                position: relative;
                top: 0;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                background: url('~@/assets/images/icons/social.svg') no-repeat $color-white;
                background-size: auto 100%;
                margin-right: 10px;
    
                &:last-child{
                    margin-right: 0;
                }
    
                &.facebook{
                    
                }
    
                &.instagram{
                    background-position: -40px 0;
                }
    
                &.linkedin{
                    background-position: -80px 0;
                }
    
                &.twitter{
                    background-position: -120px 0;
                }
    
                &.youtube{
                    background-position: -150px 0;
                }
    
            
                @media (min-width: 576px){
                    &:hover{
                        top: -3px;
                    }
                }
            }
        }
    
        .contacts{
            margin-bottom: 20px;
    
            a{
                color: $color-white;
                line-height: 30px;
            }
        }
    
        .address{
            color: $color-white;
            
            a{
                display: inline-block;
                color: $color-white;
    
                small{
                    display: inline-block;
                    border-bottom: 4px solid rgba($color: $color-white, $alpha: 0.2);
                }
            }
        }
    }

    .menu-mobile{
        .contact-us{
            text-align: center;

            .social{
                justify-content: center;
            }
        }
    }
    
    footer{
        .contact-us{
            @media (max-width: 992px){
                text-align: center;
            }

            .social{
                @media (max-width: 992px){
                    justify-content: center;
                }
            }
        }
    }

</style>