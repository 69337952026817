<template>
    <section class="mosaic emphasis">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-4">
                    <a :href="'/' + flagSelected + '/' + dataSource.boxes[0].url">
                        <h3>{{dataSource.boxes[0].title}}</h3>
                        <p>{{dataSource.boxes[0].text}}</p>
                        <div class="bt-secondary">{{dataSource.boxes[0].cta}}</div>
                    </a>
                </div>

                <div class="col-12 col-sm-6 col-lg-4">
                    <img :src="'/images/content/' + dataSource.attachments[0]">
                </div>

                <div class="col-12 col-sm-6 col-lg-4">
                    <a :href="'/' + flagSelected + '/' + dataSource.boxes[1].url">
                        <h3>{{dataSource.boxes[1].title}}</h3>
                        <p>{{dataSource.boxes[1].text}}</p>
                        <div class="bt-secondary">{{dataSource.boxes[1].cta}}</div>
                    </a>
                </div>

                <div class="col-12 col-sm-6 col-lg-4">
                    <img :src="'/images/content/' + dataSource.attachments[1]">
                </div>

                <div class="col-12 col-sm-6 col-lg-4">
                    <a :href="'/' + flagSelected + '/' + dataSource.boxes[2].url">
                        <h3>{{dataSource.boxes[2].title}}</h3>
                        <p>{{dataSource.boxes[2].text}}</p>
                        <div class="bt-secondary">{{dataSource.boxes[2].cta}}</div>
                    </a>
                </div>

                <div class="col-12 col-sm-6 col-lg-4">
                    <img :src="'/images/content/' + dataSource.attachments[2]">
                </div>
            </div>
        </div>
    </section>
</template>


<script>
    export default {
        props: {
            dataSource: {}
        },

        data (){
            return {
                flagSelected: ""
            }
        },

        async mounted(){
            this.flagUrl()
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_elements.scss';

    .mosaic{
        @include paddingSectionTop;
        @include marginSection;
        
        .row{
            margin: 0;

            .col-12{
                padding: 0;

                @media (max-width: 576px){
                    &:nth-of-type(1){
                        order: 2;
                        margin-bottom: 20px;
                    }
                    &:nth-of-type(2){
                        order: 1;
                    }
                    
                    &:nth-of-type(3){
                        order: 4;
                        margin-bottom: 20px;
                    }
                    &:nth-of-type(4){
                        order: 3;
                    }
                    
                    &:nth-of-type(5){
                        order: 6;
                    }
                    &:nth-of-type(6){
                        order: 5;
                    }
                }
                
                @media (min-width: 576px) and (max-width: 992px){
                    &:nth-of-type(1){
                        order: 2;
                    }
                    &:nth-of-type(2){
                        order: 1;
                    }
                    
                    &:nth-of-type(3){
                        order: 3;
                    }
                    &:nth-of-type(4){
                        order: 4;
                    }
                    
                    &:nth-of-type(5){
                        order: 6;
                    }
                    &:nth-of-type(6){
                        order: 5;
                    }
                }
                
                @media (min-width: 992px){
                    &:nth-of-type(1){
                        order: 2;
                    }
                    &:nth-of-type(2){
                        order: 1;
                    }
                    
                    &:nth-of-type(3){
                        order: 6;
                    }
                    &:nth-of-type(4){
                        order: 3;
                    }
                    
                    &:nth-of-type(5){
                        order: 4;
                    }
                    &:nth-of-type(6){
                        order: 5;
                    }
                }

                a{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    padding: 30px 15px;
                    background: #eee;

                    @media (min-width: 992px){
                        &:hover{
                            background: #f9f9f9;

                            p{
                                color: $color-primary;
                            }
                        }
                    }
        
                    h3{
                        @media (max-width: 576px){
                            font-size: 18px;
                            line-height: 20px;
                            margin-bottom: 13px;
                        }
                        
                        @media (min-width: 576px){
                            font-size: 26px;
                            line-height: 28px;
                            margin-bottom: 18px;
                        }
                    }
        
                    p{
                        overflow: hidden;
                        text-align: center;
                        margin-bottom: 25px;

                        @media (max-width: 576px){
                            font-size: 13px;
                            line-height: 18px;
                        }

                        @media (min-width: 576px){
                            font-size: 14px;
                            line-height: 19px;
                        }
                        
                        // max-height: 48px;
                    }
                }
            }
        }
    }
</style>