<template>
    <div class="content-page featured-image">
        <Header />

        <div class="image-header">
            <h1 class="title-primary">
                <span>{{aboutUs.title}}</span>
                <small>{{aboutUs.subtitle}}</small>
            </h1>

            <img :src="'/images/content/' + aboutUs.attachments.header">
        </div>
        
        
        <section class="about-us">
            <div class="container">
                <div class="row mb-5">

                    <div class="col-12 col-lg-6 order-1 order-lg-2 mb-5 photo-about">
                        <div>
                            <img :src="'/images/content/' + aboutUs.attachments.content01" alt="Zenith Saúde">
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 order-2 order-lg-1 mb-5 py-lg-4 d-lg-flex flex-wrap align-items-center">
                        <div>
                            <h2 class="subtitle">Zenith Saúde</h2>
                            <div v-html="aboutUs.text01"></div>
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 order-3 order-lg-3 mb-5 photo-about">
                        <div>
                            <img :src="'/images/content/' + aboutUs.attachments.content02" alt="Zenith Saúde">
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 order-4 order-lg-4 mb-5 py-lg-4 d-lg-flex flex-wrap align-items-center">
                        <div>
                            <h2 class="subtitle">{{aboutUs.subtitle02}}</h2>
                            <div v-html="aboutUs.text02"></div>
                        </div>
                    </div>
                </div>

                <div class="row mb-5">
                    <div class="col-12 mb-sm-5">
                        <div class="txt-emphasis">
                            <p>“um estado de completo bem estar físico, mental e social e não apenas como a ausência de infeções ou enfermidades”</p>
                            <p><em><smalL>Fonte OMS</smalL></em></p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <h2 class="title-primary">
                            <span>{{aboutUs.titleGallery}}</span>
                            <small>{{aboutUs.subtitleGallery}}</small>
                        </h2>

                        <div class="gallery">
                            <div><img src="/images/gallery/zenith-saude-01.jpg" alt="Zenith Saúde" @click="gallery('zenith-saude-01.jpg')"></div>
                            <div><img src="/images/gallery/zenith-saude-02.jpg" alt="Zenith Saúde" @click="gallery('zenith-saude-02.jpg')"></div>
                            <div><img src="/images/gallery/zenith-saude-03.jpg" alt="Zenith Saúde" @click="gallery('zenith-saude-03.jpg')"></div>
                            <div><img src="/images/gallery/zenith-saude-04.jpg" alt="Zenith Saúde" @click="gallery('zenith-saude-04.jpg')"></div>
                            <div><img src="/images/gallery/zenith-saude-05.jpg" alt="Zenith Saúde" @click="gallery('zenith-saude-05.jpg')"></div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <TeamHome />
        <Map />
        <Footer dataSource="Sobre nós" />
    </div>


    <!-- MODAL GALLERY -->
    <div class="modal modal-gallery" v-if="modalGallery.modal">
        <div class="modal-cont">
            <div class="modal-close" @click="modalGallery.modal = false"></div>
            <div class="modal-scroll">
                <img :src="'/images/gallery/' + modalGallery.img" class="photo">
            </div>
        </div>
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Header.vue";
    import TeamHome from "@/components/TeamHome.vue";
    import Map from "@/components/Map.vue";
    import Footer from "@/components/Footer.vue";
    
    import getMyFavouriteLanguage from "@/services/language.js";
    
    import aboutUs_en from "@/contents/aboutUs-pt.json";
    import aboutUs_es from "@/contents/aboutUs-pt.json";
    import aboutUs_fr from "@/contents/aboutUs-pt.json";
    import aboutUs_pt from "@/contents/aboutUs-pt.json";

    export default {
        components: {
            Header,
            TeamHome,
            Map,
            Footer
        },
        data() {
            return {
                flagSelected: {},

                aboutUs: {},

                modalGallery: {
                    modal: false,
                    img: ""
                }
            }
        },

        // async mounted() {
        
        // },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            getMyLanguage(group, code) {
                return getMyFavouriteLanguage(group, code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            languageContent(){
                if ( this.flagSelected === 'en' ){
                    this.aboutUs = aboutUs_en
                } else if ( this.flagSelected === 'es' ){
                    this.aboutUs = aboutUs_es
                } else if ( this.flagSelected === 'fr' ){
                    this.aboutUs = aboutUs_fr
                } else {
                    this.aboutUs = aboutUs_pt
                }
            },

            gallery(value){
                this.modalGallery.img = value
                this.modalGallery.modal = true
            }
        },

        created() {
            this.flagUrl()
            this.languageContent()
            this.goTop()
        }
    };
</script>

<style src="@/assets/scss/_content.scss" lang="scss" scoped />
<style src="./custom-sobre-nos.scss" lang="scss" scoped />