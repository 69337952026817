<template>
    <section class="treatments emphasis">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2 class="title-primary">
                        <span>Em destaque</span>
                        <small>Os melhores tratamentos para os seus cuidados</small>
                    </h2>
                </div>
            </div>

            <div class="list">
                <Carousel :items-to-show="1" :settings="settings" :breakpoints="breakpoints" :if="breakpoints.aaa">
                    <Slide v-for="(slider, index) in dataSource" :key="index">
                        <!-- <a :href="slider.link" class="product"> -->
                        <a :href='"/" + flagSelected + "/tratamento/" + slider.slug'>
                            <span class="image">
                                <img :src='"/images/content/" + slider.img' :alt="slider.name">
                            </span>

                            <h2 class="title">{{slider.name}}</h2>
                            <p>{{slider.cta}}</p>
                        </a>
                    </Slide>
                    
                    <template #addons>
                        <Navigation />
                    </template>
                </Carousel>
            </div>
        </div>
    </section>
</template>


<script>
    import { defineComponent } from 'vue'
    import 'vue3-carousel/dist/carousel.css'
    import { Carousel, Navigation, Slide } from 'vue3-carousel'
    
    export default defineComponent({
        props: {
            dataSource: {}
        },

        components: {
            Carousel,
            Slide,
            Navigation,
        },
        
        data (){
            return {
                flagSelected: {},
                
                settings: {
                itemsToShow: 1,
                snapAlign: 'center',
                wrapAround: true,
                autoplay: 5000,
                transition: 1000
                },

                breakpoints: {               
                    700: {
                        itemsToShow: 3
                    }
                },
            }
        },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            }
        },

        created (){
            this.flagUrl()
        }
    })
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_elements.scss';
    @import '@/assets/scss/_carousel.scss';
    @import "@/assets/scss/_content.scss";

    .treatments{
        @include marginSection;

        @media (min-width: 700px){
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .list{
        a{
            width: 100% !important;
            height: calc(100% - 30px);
            margin: 0 14px 30px 14px;
        }
    }
</style>