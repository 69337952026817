import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home/Home.vue'
import SobreNos from '../views/SobreNos/SobreNos.vue'
import MedicinaEstetica from '../views/MedicinaEstetica/MedicinaEstetica.vue'
import MedicinaEsteticaTag from '../views/MedicinaEstetica/MedicinaEsteticaTag.vue'
import BelezaBemEstar from '../views/BelezaBemEstar/BelezaBemEstar.vue'
import BelezaBemEstarTag from '../views/BelezaBemEstar/BelezaBemEstarTag.vue'
import DetalhesTratamento from '../views/DetalhesTratamento/DetalhesTratamento.vue'
import Cursos from '../views/Cursos/Cursos.vue'
import CursosTag from '../views/Cursos/CursosTag.vue'
import DetalhesCurso from '../views/Cursos/DetalhesCurso.vue'
import Produtos from '../views/Produtos/Produtos.vue'
import Produto from '../views/Produtos/Produto.vue'
import Contacto from '../views/Contacto/Contacto.vue'

import NotFound from '../views/NotFound/NotFound.vue'

const routes = [
  { path: '/', name: 'redirect', redirect: '/pt/', component: Home, meta: {title:'Zenith Saúde'} },
  { path: '/:lang/', name: 'Home', component: Home, meta: {title:'Zenith Saúde'} },
  // { path: '/:lang/sobre-nos', name: 'SobreNos', component: SobreNos, meta: {title: getMyLanguage("seo","title.home") + ' | Zenith Saúde'} },
  { path: '/:lang/sobre-nos', name: 'SobreNos', component: SobreNos, meta: {title: 'Sobre nós | Zenith Saúde'} },
  { path: '/:lang/medicina-estetica', name: 'MedicinaEstetica', component: MedicinaEstetica, meta: {title: 'Medicina Estética | Zenith Saúde'} },
  { path: '/:lang/medicina-estetica/:slugTag', name: 'MedicinaEsteticaTag', component: MedicinaEsteticaTag, meta: {title: 'Medicina Estética | Zenith Saúde'} },
  { path: '/:lang/beleza-e-bem-estar', name: 'BelezaBemEstar', component: BelezaBemEstar, meta: {title: 'Beleza e bem-estar | Zenith Saúde'} },
  { path: '/:lang/beleza-e-bem-estar/:slugTag', name: 'BelezaBemEstarTag', component: BelezaBemEstarTag, meta: {title: 'Beleza e bem-estar | Zenith Saúde'} },
  { path: '/:lang/tratamento/:slugTreatment', name: 'DetalhesTratamento', component: DetalhesTratamento, meta: {title: 'Tratamento | Zenith Saúde'} },
  { path: '/:lang/cursos', name: 'Cursos', component: Cursos, meta: {title: 'Cursos | Zenith Saúde'} },
  { path: '/:lang/cursos/:slugTag', name: 'CursosTag', component: CursosTag, meta: {title: 'Cursos | Zenith Saúde'} },
  { path: '/:lang/curso/:slugCourse', name: 'DetalhesCurso', component: DetalhesCurso, meta: {title: 'Curso | Zenith Saúde'} },
  { path: '/:lang/produtos', name: 'Produtos', component: Produtos, meta: {title: 'Produtos | Zenith Saúde'} },
  { path: '/:lang/nome-do-produto', name: 'Produto', component: Produto, meta: {title: 'Nome do produto | Zenith Saúde'} },
  { path: '/:lang/contacto', name: 'Contacto', component: Contacto, meta: {title: 'Contacto | Zenith Saúde'} },
  
  { path: '/:lang/:catchAll(.*)', component: NotFound, meta: {title: '404 | Zenith Saúde'} },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // Get the page title from the route meta data that we have defined
  // See further down below for how we setup this data
  const title = to.meta.title

  //Take the title from the parameters
  const titleFromParams = to.params.pageTitle;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title
  }
  // If we have a title from the params, extend the title with the title
  // from our params
  if (titleFromParams) {
    document.title = `${titleFromParams} - ${document.title}`;
  }
  // Continue resolving the route
  next()
})

export default router

// import getMyFavouriteLanguage from "@/services/language.js";
// function getMyLanguage(group, code) {
//   return getMyFavouriteLanguage(group, code)
// }