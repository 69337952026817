<template>
<div class="main">
    <router-view/>

    <CookieConsent />
</div>

</template>

<script>
    import CookieConsent from '@/components/CookieConsent.vue';
    import getMyFavouriteLanguage from "@/services/language.js";
    
    // import api from '@/config/api.js'
    export default {
        components: {
            CookieConsent
        },
        data: function () {
            return {
                language: 'pt',
                group: 'dne-market',
                dataLanguage: [],
                dataPhaseCurrent: [],
                flagSelected: 'pt',
                flagImg: '51021d86-49e9-4beb-804b-dfadf7a51300',
                statusLogin: {
                    logged: false
                },
                loginErrors: ""
            }
        },

        beforeMount(){
            // this.loginStatus();
            this.flagUrl()

            if ( !this.$cookies.get("idCart") ){
                this.$cookies.set("idCart","00000000-0000-0000-0000-000000000000")  
            }
        },

        async mounted(){
            // const resumeLanguage = await api.get('api/v1/platform/language')
            // this.dataLanguage = resumeLanguage.data
            // this.$store.commit('allLanguages',this.dataLanguage)

            // this.flagImg = this.dataLanguage.filter(lang => lang.code === this.flagSelected)[0].flag
            // this.$store.commit('preLanguageFlag',this.flagImg)
        },

        methods: {
            // loginStatus(){
            //     const tokenValue = localStorage.getItem('accessToken')

            //     if ( tokenValue != undefined || tokenValue != null ){
            //         api.get('/api/v1/platform/account/profile/')
            //         .then(response => {
            //             this.$store.commit('profile',response.data)
            //             this.$store.commit('loginStatus', true)
            //         })
            //         .catch(error => {
            //             console.log(JSON.stringify(error))
            //             let errorsLogin = JSON.stringify(error.response.status)
            //             this.loginErrors = errorsLogin

            //             if ( errorsLogin == 401 || errorsLogin == 403 ){
            //                 localStorage.removeItem('accessToken');
            //             }
            //         });
            //     }

            //     this.statusLogin.logged = false
            //     this.$store.commit('loginStatus', false)
            // },

            flagUrl(){
                let code = String(window.location.pathname).split("/")[1];
                let flag = '51021d86-49e9-4beb-804b-dfadf7a51300'
                let language = "Portugues"

                if ( !code ) {
                    code = 'pt',
                    this.$store.commit('preLanguageFlag',flag)
                    localStorage.setItem('codeLanguage',code);
                    localStorage.setItem('flagLanguage','51021d86-49e9-4beb-804b-dfadf7a51300');
                } else{
                    this.flagSelected = code
                    this.$store.commit('preLanguage',code)
                    this.$store.commit('preLanguageName',language)
                }
            },

            changeLanguage(parLanguage){
                this.language = parLanguage;
                let url = String(window.location.pathname).split("/");
                
                url[1] = parLanguage;
                window.location.href = `${url.join('/')}`;
            },
            
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            }
        }
    }
</script>

<style src="@/assets/scss/_custom-app.scss" lang="scss" />