<template>
    <div class="content-page">
        <Header />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="breadcrumb">
                        <li><a :href="'/' + flagSelected + '/'"><span class="home"></span></a></li>
                        <li><a :href="'/' + flagSelected + '/cursos'"><span>Cursos</span></a></li>
                        <li class="d-none d-md-flex"><a :href="'/' + flagSelected + '/cursos/' + details.category_slug"><span>{{details.category}}</span></a></li>
                        <li class="active"><span>{{details.name}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
        
        <section class="details-course">
            <div class="container">
                <div class="row mb-5">
                    <div class="col-12 col-lg-6 order-lg-2 mb-5 mb-lg-0 photo-course">
                        <div>
                            <img :src="'/images/content/' + details.img" :alt="details.name">
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 order-lg-1 d-lg-flex align-items-center">
                        <div>
                            <a :href="'/' + flagSelected + '/tratamento/' + details.slug"><h1 class="title-primary title-left-sm"><span>{{details.name}}</span></h1></a>
                            <div v-html="details.text"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-fluid mb-5 topics-course">
                <div class="row">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h4 class="subtitle">Temas sugeridos:</h4>
                                <div class="mb-5" v-html="details.topics"></div>
                                <div class="complement mb-5" v-if="details.complement" v-html="details.complement"></div>

                                <div class="txt-emphasis">Para maiores informações relativas a datas e valores, por favor contactar a clínica.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container mb-5">
                <div class="row">
                    <div class="col">
                        <h2 class="title-primary">
                            <span>Gaeria de fotos</span>
                            <small>{{details.name}}</small>
                        </h2>

                        <div class="gallery">
                            <div><img src="/images/gallery/zenith-saude-01.jpg" alt="Zenith Saúde" @click="gallery('zenith-saude-01.jpg')"></div>
                            <div><img src="/images/gallery/zenith-saude-02.jpg" alt="Zenith Saúde" @click="gallery('zenith-saude-02.jpg')"></div>
                            <div><img src="/images/gallery/zenith-saude-03.jpg" alt="Zenith Saúde" @click="gallery('zenith-saude-03.jpg')"></div>
                            <div><img src="/images/gallery/zenith-saude-04.jpg" alt="Zenith Saúde" @click="gallery('zenith-saude-04.jpg')"></div>
                            <div><img src="/images/gallery/zenith-saude-05.jpg" alt="Zenith Saúde" @click="gallery('zenith-saude-05.jpg')"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="others-courses">
            <div class="container">
                <div class="row list" v-if="filterTag.length">
                    <div class="col-12">
                        <h2 class="title-primary">
                            <span>Outros cursos</span>
                            <small>{{details.tag}}</small>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/curso/' + course.slug" v-for="(course,index) in filterTag" :key="index">
                        <span class="image">
                            <span>
                                <img :src="'/images/content/' + course.img">
                            </span>
                        </span>
                        <h2 class="title">{{course.name}}</h2>
                        <p>{{course.cta}}</p>
                    </a>
                </div>
            </div>
        </section>

        <Footer :dataSource="details.name" />
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";
    
    import getMyFavouriteLanguage from "@/services/language.js";

    import courses_en from "@/contents/courses-pt.json"
    import courses_es from "@/contents/courses-pt.json"
    import courses_fr from "@/contents/courses-pt.json"
    import courses_pt from "@/contents/courses-pt.json"

    export default {
        components: {
            Header,
            Footer
        },

        data() {
            return {
                flagSelected: {},
                
                details: []
            }
        },
    
        // async mounted(){
            
        // },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            getMyLanguage(group, code) {
                return getMyFavouriteLanguage(group, code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            languageContent(){
                if ( this.flagSelected === 'en' ){
                    this.courses = courses_en
                } else if ( this.flagSelected === 'es' ){
                    this.courses = courses_es
                } else if ( this.flagSelected === 'fr' ){
                    this.courses = courses_fr
                } else {
                    this.courses = courses_pt
                }

                this.details = this.courses.filter(filterSlug => filterSlug.slug == this.$route.params.slugCourse)[0]
            },

            activeMenu(){
                $(document).ready(function(){
                    $("header #nav .courses").addClass("active")
                })
            }
        },

        computed: {
            filterTag() {
                if (this.courses.length === 0) {
                    return [];
                }

                return this.courses.filter(treatment => treatment.tag === this.details.tag && treatment.slug != this.details.slug);
            },
        },

        created() {
            this.flagUrl()
            this.languageContent()
            this.activeMenu()
            this.goTop()

            document.title = this.details.name + " | Zenith Saúde"
            document.head.querySelector("meta[name=description]").content = this.details.cta;
        }
    }
</script>

<style src="./custom-detalhes-curso.scss" lang="scss" scoped />
<style src="@/assets/scss/_content.scss" lang="scss" scoped />

<style lang="scss">
    .topics-course{
        ul{
            margin: 0;

            li{
                margin-bottom: 5px;

                strong{
                    display: inline-block;
                    padding: 8px 16px;
                    background: #9e85bb;
                    border-radius: 12px;
                    color: #fff;
                }
            }
        }
    }
</style>