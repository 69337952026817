<template>
    <ul class="menu" id="nav">
        <!-- <li><router-link :to="{ name: 'AboutUs' }">Sobre nós</router-link></li>
        <li><router-link :to="{ name: 'AestheticMedicine' }">Medicina estética</router-link></li>
        <li><router-link :to="{ name: 'BeautyWellness' }">Beleza e bem-estar</router-link></li>
        <li><router-link :to="{ name: 'Courses' }">Cursos</router-link></li>
        <li><router-link :to="{ name: 'Products' }">Produtos</router-link></li>
        <li><router-link :to="{ name: 'Contact' }">Contacto</router-link></li> -->
        
        <li><a :href='"/" + flagSelected + "/sobre-nos"' class="about-us">{{ getMyLanguage("Menu","about-us") }}</a></li>
        <li><a :href='"/" + flagSelected + "/medicina-estetica"' class="aesthetic-medicine">{{ getMyLanguage("Menu","aesthetic-medicine") }}</a></li>
        <li><a :href='"/" + flagSelected + "/beleza-e-bem-estar"' class="beauty-and-wellness">{{ getMyLanguage("Menu","beauty-and-wellness") }}</a></li>
        <li><a :href='"/" + flagSelected + "/cursos"' class="courses">{{ getMyLanguage("Menu","courses") }}</a></li>
        <!-- <li><a :href='"/" + flagSelected + "/produtos"' class="">{{ getMyLanguage("Menu","products") }}</a></li> -->
        <li><a :href='"/" + flagSelected + "/contacto"' class="contacts">{{ getMyLanguage("Menu","contacts") }}</a></li>
        <li v-if="dataSource"><a href="#agendar-avaliacao" class="schedule">{{ getMyLanguage("Menu","schedule-evaluation") }}</a></li>
        <li v-if="!dataSource"><a :href='"/" + flagSelected + "/#agendar-avaliacao"' class="schedule">{{ getMyLanguage("Menu","schedule-evaluation") }}</a></li>
    </ul>
</template>


<script>
    const $ = require("jquery");
    window.$ = $;

    import getMyFavouriteLanguage from "@/services/language.js";

    export default{
        props: {
            dataSource: {},
        },

        data (){
            return {
                flagSelected: "",
                url: ""
            }
        },

        methods: {
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            },

            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },

            menuActive(){
                setTimeout(() => {
                    var nav = document.getElementById("nav"),
                    anchor = nav.getElementsByTagName("a"),
                    current = window.location;

                    for (var i = 0; i < anchor.length; i++) {
                        if(anchor[i].href == current) {
                            anchor[i].className = "active";

                        }
                    }
                }, 100);
            }
        },

        created() {
            this.flagUrl()
            this.menuActive()
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_colors.scss';

    .menu{
        display: flex;

        @media (max-width: 1270px){
            flex-direction: column;
        }

        @media (min-width: 1270px){
        }

        li{
            @media (min-width: 1270px){
                padding: 0 2px;
            }

            a{
                display: flex;
                align-items: center;
                padding: 0 8px;
                height: 40px;
                line-height: 16px;
                font-size: 14px;
                border-radius: 8px;
                color: $color-white;
                
                &.active{
                    background: rgba($color: $color-white, $alpha: 0.15);
                }

                &.schedule{
                    // background: rgba($color: $color-white, $alpha: 0.25);
                    background: none;
                    border: 1px solid rgba($color: $color-white, $alpha: 0.5);
                }

                @media (min-width: 1270px){
                    &:hover{
                        background: rgba($color: $color-white, $alpha: 0.15);
                    }
                }
            }
        }
    }


    body:not(.headerFixed){
        .content-page{
            &.featured-image{
                .menu{
                    li{
                        a{
                            &.schedule{
                                background: $color-primary;
                                border-color: $color-primary;
                            }
                        }
                    }
                }
            }
        }
    }


    // MENU MOBILE
    .menuMobile{
        @media (min-width: 1270px){
            display: none;
        }
        
        @media (max-width: 1270px){
            position: fixed;
            top: 0;
            left: -100%;
            z-index: 999999;
            width: 100%;
            height: 100%;

            &.menuMobileOpened{
                left: 0;

                &:after{
                    opacity: 1;
                }

                .closeMenuMobile{
                    opacity: 1;
                    right: 0;
                    transition: .6s;
                    transition-delay: .6s;
                }
            }

            .menu{
                position: relative;
                z-index: 20;

                @media (max-width: 1270px){
                    margin-bottom: 50px;
                }
                
                li{
                    margin-bottom: 7px;
    
                    a{
                        border: 1px solid rgba($color: $color-white, $alpha: 0.3);
                        font-size: 14px;
                        color: $color-white;
                        display: block;
                        text-align: center;
                        height: 40px;
                        line-height: 40px;
                        padding: 0 15px;
                        white-space: nowrap;
                        position: relative;
    
                        &.schedule{
                            border: none;
                            background: rgba($color: $color-white, $alpha: 0.20) !important;
                        }
                    }
                }
            }
        }
    }
</style>