import axios from 'axios';

let code = ""

if ( localStorage.getItem('langCode') === "" || localStorage.getItem('langCode') === null ){
    code = "en"
} else {
    code = localStorage.getItem('langCode')
}

const api = axios.create({
    baseURL: process.env.VUE_APP_MARKET_URL,
    headers: { 'content-language': code }
});

export default api;