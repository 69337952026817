<template>
    <div class="content-page featured-image">
        <Header />

        <div class="image-header">
            <h1 class="title-primary">
                <span>Beleza e bem-estar</span>
                <small>Experimente a beleza e o bem-estar transformadores com nossos tratamentos estéticos.</small>
            </h1>

            <img src="/images/content/beleza-bem-estar-header.jpg">
        </div>
        
        
        <section class="beleza-bem-estar">
            <div class="container">

                <div class="row list">
                    
                    <!-- LIMPEZA DE PELE -->
                    <div class="col-12" v-if="filterLimpezaPele.length">
                        <h2 class="title-primary title-left">
                            <span>Limpeza de pele</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/tratamento/' + treatment.slug" v-for="(treatment,index) in filterLimpezaPele" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + treatment.img">
                        </span>
                        <h2 class="title">{{treatment.name}}</h2>
                        <p>{{treatment.cta}}</p>
                    </a>
                    
                    
                    
                    <div class="bar-category" v-if="filterPeelings.length"></div>                    

                    <!-- PEELINGS -->
                    <div class="col-12" v-if="filterPeelings.length">
                        <h2 class="title-primary title-left">
                            <span>Peelings</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/tratamento/' + treatment.slug" v-for="(treatment,index) in filterPeelings" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + treatment.img">
                        </span>
                        <h2 class="title">{{treatment.name}}</h2>
                        <p>{{treatment.cta}}</p>
                    </a>
                    
                    
                    
                    <div class="bar-category" v-if="FilterMassagemFacial.length"></div>                    

                    <!-- MASSAGEM FACIAL -->
                    <div class="col-12" v-if="FilterMassagemFacial.length">
                        <h2 class="title-primary title-left">
                            <span>Massagem facial</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/tratamento/' + treatment.slug" v-for="(treatment,index) in FilterMassagemFacial" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + treatment.img">
                        </span>
                        <h2 class="title">{{treatment.name}}</h2>
                        <p>{{treatment.cta}}</p>
                    </a>
                    
                    
                    
                    <div class="bar-category" v-if="filterTratamentoEstrias.length"></div>

                    <!-- TRATAMENTO PARA ESTRIAS -->
                    <div class="col-12" v-if="filterTratamentoEstrias.length">
                        <h2 class="title-primary title-left">
                            <span>Tratamento para estrias</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/tratamento/' + treatment.slug" v-for="(treatment,index) in filterTratamentoEstrias" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + treatment.img">
                        </span>
                        <h2 class="title">{{treatment.name}}</h2>
                        <p>{{treatment.cta}}</p>
                    </a>
                    
                    
                    
                    <div class="bar-category" v-if="filterTratamentoGorduraLocalizada.length"></div>                    
                    
                    <!-- TRATAMENTO PARA GORDURA LOCALIZADA -->
                    <div class="col-12" v-if="filterTratamentoGorduraLocalizada.length">
                        <h2 class="title-primary title-left">
                            <span>Tratamentos para gordura localizada</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/tratamento/' + treatment.slug" v-for="(treatment,index) in filterTratamentoGorduraLocalizada" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + treatment.img">
                        </span>
                        <h2 class="title">{{treatment.name}}</h2>
                        <p>{{treatment.cta}}</p>
                    </a>
                    
                    
                    
                    <div class="bar-category" v-if="filterMassagens.length"></div>

                    <!-- MASSAGENS -->
                    <div class="col-12" v-if="filterMassagens.length">
                        <h2 class="title-primary title-left">
                            <span>Massagens</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/tratamento/' + treatment.slug" v-for="(treatment,index) in filterMassagens" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + treatment.img">
                        </span>
                        <h2 class="title">{{treatment.name}}</h2>
                        <p>{{treatment.cta}}</p>
                    </a>
                    
                </div>
            </div>
        </section>

        <Footer dataSource="Beleza e bem-estar" />
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";
    
    import getMyFavouriteLanguage from "@/services/language.js";

    import treatments_en from "@/contents/treatments-pt.json"
    import treatments_es from "@/contents/treatments-pt.json"
    import treatments_fr from "@/contents/treatments-pt.json"
    import treatments_pt from "@/contents/treatments-pt.json"

    export default {
        components: {
            Header,
            Footer
        },

        data() {
            return {
                flagSelected: {},
            }
        },

        // async mounted() {
        
        // },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            getMyLanguage(group, code) {
                return getMyFavouriteLanguage(group, code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            languageContent(){
                if ( this.flagSelected === 'en' ){
                    this.treatments = treatments_en
                } else if ( this.flagSelected === 'es' ){
                    this.treatments = treatments_es
                } else if ( this.flagSelected === 'fr' ){
                    this.treatments = treatments_fr
                } else {
                    this.treatments = treatments_pt
                }
            },
        },

        computed: {
            filterLimpezaPele() {
                if (this.treatments.length === 0) {
                return [];
                }

                return this.treatments.filter(treatment => treatment.tag === 'Limpeza de pele');
            },

            filterPeelings() {
                if (this.treatments.length === 0) {
                return [];
                }

                return this.treatments.filter(treatment => treatment.tag === 'Peelings');
            },

            FilterMassagemFacial() {
                if (this.treatments.length === 0) {
                return [];
                }

                return this.treatments.filter(treatment => treatment.tag === 'Massagem facial');
            },

            filterTratamentoEstrias() {
                if (this.treatments.length === 0) {
                return [];
                }

                return this.treatments.filter(treatment => treatment.tag === 'Tratamento para estrias');
            },

            filterTratamentoGorduraLocalizada() {
                if (this.treatments.length === 0) {
                return [];
                }

                return this.treatments.filter(treatment => treatment.tag === 'Tratamentos para gordura localizada');
            },

            filterMassagens() {
                if (this.treatments.length === 0) {
                return [];
                }

                return this.treatments.filter(treatment => treatment.tag === 'Massagens');
            }
        },

        created() {
            this.flagUrl()
            this.languageContent()
            this.goTop()

            document.title = "Beleza e bem-estar | Zenith Saúde"
            document.head.querySelector("meta[name=description]").content = "Experimente a beleza e o bem-estar transformadores com nossos tratamentos estéticos.";
        }
    };
</script>

<style src="@/assets/scss/_content.scss" lang="scss" scoped />
<style src="./custom-beleza-bem-estar.scss" lang="scss" scoped />