<template>
    <div class="content-page">
        <Header />
        
        <section class="product-details emphasis">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-6 col-xl-7 mb-5">
                        <img src="/images/content/product-example.jpg" alt="Nome do produto">
                    </div>

                    <div class="col-12 col-lg-6 col-xl-5">
                        <h1 class="title-product mb-4">Nome do produto</h1>
                        
                        <div class="short-description p-3 mb-4">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Asperiores, repellat iste eum ad alias dolores veritatis fugit officia.</p>
                        </div>

                        <div class="row purchase mb-5 align-items-center">
                            <div class="col-12 col-xl-4 offset-xl-1 price mb-4 mb-xl-0">
                                <h6 class="mb-0 text-center text-xl-left">
                                    <del>128 €</del>
                                    <strong>99 €</strong>
                                </h6>
                                <span></span>
                            </div>

                            <div class="col-12 col-xl-6 bts">
                                <div class="bt-primary">Comprar</div>
                                <div class="bt-secondary">
                                    <span>Comprar com cripto</span>
                                    <small>DNE Token</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <h4 class="subtitle">Descrição</h4>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, maiores et soluta hic beatae magnam nobis nam repellendus perferendis excepturi atque distinctio magni reiciendis saepe officiis itaque dolorum nemo. Delectus?</p>
                        <p>Quidem ex a distinctio esse, earum dolore iusto doloremque! Ex dicta veniam, quia harum sapiente est hic rem dolore optio! Adipisci odio tenetur molestiae neque in facere nostrum magni beatae?</p>
                        <p>Nostrum tempore et iusto voluptate nobis unde inventore placeat cumque vero, repudiandae, animi sit quas aut explicabo, ab fugit sapiente rem necessitatibus optio labore dicta nam incidunt tempora assumenda! Asperiores.</p>
                    </div>
                </div>
            </div>
        </section>

        <RelatedProducts :dataSource="productsCarousel" />

        <Footer dataSource="Página de produtos" />
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Header.vue";
    import RelatedProducts from "@/components/ProductsCarousel.vue";
    import Footer from "@/components/Footer.vue";
    
    import getMyFavouriteLanguage from "@/services/language.js";

    export default {
        components: {
            Header,
            RelatedProducts,
            Footer
        },
        data() {
            return {
                flagSelected: {},

                productsCarousel: {
                    infos: {
                        type: "related",
                        title: "Produtos relacionados",
                        short: "Veja o que temos também"
                    },
                    products: [
                        {
                            link: "#",
                            img: "product-example.jpg",
                            name: "Kit Banho Super Hidratante"
                        },
                        {
                            link: "#",
                            img: "product-example.jpg",
                            name: "Kit Banho Super Hidratante"
                        },
                        {
                            link: "#",
                            img: "product-example.jpg",
                            name: "Kit Banho Super Hidratante"
                        },
                        {
                            link: "#",
                            img: "product-example.jpg",
                            name: "Kit Banho Super Hidratante"
                        },
                        {
                            link: "#",
                            img: "product-example.jpg",
                            name: "Kit Banho Super Hidratante"
                        },
                        {
                            link: "#",
                            img: "product-example.jpg",
                            name: "Kit Banho Super Hidratante"
                        },
                        {
                            link: "#",
                            img: "product-example.jpg",
                            name: "Kit Banho Super Hidratante"
                        },
                        {
                            link: "#",
                            img: "product-example.jpg",
                            name: "Kit Banho Super Hidratante"
                        }
                    ]
                },
            }
        },

        // async mounted() {
        
        // },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            getMyLanguage(group, code) {
                return getMyFavouriteLanguage(group, code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            }
        },

        created() {
            this.flagUrl()
            this.goTop()
        }
    };
</script>

<style src="@/assets/scss/_content.scss" lang="scss" scoped />
<style src="./custom-produtos.scss" lang="scss" scoped />