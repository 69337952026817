<template>
    <div class="content-page">
        <Header />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="breadcrumb">
                        <li><a :href="'/' + flagSelected + '/'"><span class="home"></span></a></li>
                        <li><a :href="'/' + flagSelected + '/cursos'"><span>Cursos</span></a></li>
                        <li class="active"><span>{{filterCourses[0].category}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
        
        <section class="filter-tag">
            <div class="container">

                <div class="row list">
                    
                    <!-- FILTER TAG -->
                    <div class="col-12" v-if="filterCourses.length">
                        <h2 class="title-primary title-left">
                            <span>Cursos de {{filterCourses[0].category}}</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/curso/' + course.slug" v-for="(course,index) in filterCourses" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + course.img">
                        </span>
                        <h2 class="title">{{course.name}}</h2>
                        <p>{{course.cta}}</p>
                    </a>

                </div>
            </div>
        </section>

        <Footer :dataSource="'Cursos de ' + filterCourses[0].category" />
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";
    
    import getMyFavouriteLanguage from "@/services/language.js";

    import courses_en from "@/contents/courses-pt.json"
    import courses_es from "@/contents/courses-pt.json"
    import courses_fr from "@/contents/courses-pt.json"
    import courses_pt from "@/contents/courses-pt.json"

    export default {
        components: {
            Header,
            Footer
        },
        
        data() {
            return {
                flagSelected: {}
            }
        },

        // async mounted() {

        // },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            getMyLanguage(group, code) {
                return getMyFavouriteLanguage(group, code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            languageContent(){
                if ( this.flagSelected === 'en' ){
                    this.courses = courses_en
                } else if ( this.flagSelected === 'es' ){
                    this.courses = courses_es
                } else if ( this.flagSelected === 'fr' ){
                    this.courses = courses_fr
                } else {
                    this.courses = courses_pt
                }
                
                // this.filterCourses = this.courses.filter(filterSlug => filterSlug.tag_slug == this.$route.params.slugTag)
            },
        },

        computed: {
            filterCourses() {
                if (this.courses.length === 0) {
                    return [];
                }

                return this.courses.filter(course => course.category_slug == this.$route.params.slugTag);
            }
        },

        created() {
            this.flagUrl()
            this.languageContent()
            this.goTop()

            document.title = "Cursos de " + this.filterCourses[0].category
            document.head.querySelector("meta[name=description]").content = "Descubra o seu potencial ilimitado e aprimore as suas competências com os nossos cursos especializados na Zenith Saúde.";
        }
    };
</script>

<style src="@/assets/scss/_content.scss" lang="scss" scoped />
<style src="./custom-cursos.scss" lang="scss" scoped />