<template>
    <div class="content-page featured-image">
        <Header />
        <!-- <Slider :dataSource="home.slider" /> -->
        <SliderClean :dataSource="home.slider" />
        <Mosaic :dataSource="home.mosaic" />
        <!-- <HalfBanners :dataSource="home.halfbanners" /> -->
        <TreatmentsCarousel :dataSource="home.treatments" />
        <!-- <ProductsHome :dataSource="productsCarousel" /> -->
        <FeaturedHome :dataSource="home.featured" />
        <TeamHome />
        <Footer dataSource="Home" />
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Header.vue";
    // import Slider from "@/components/Slider.vue";
    import SliderClean from "@/components/SliderClean.vue";
    import Mosaic from "@/components/Mosaic.vue";
    // import HalfBanners from "@/components/HalfBanners.vue";
    import TreatmentsCarousel from "@/components/TreatmentsCarousel.vue";
    import FeaturedHome from "@/components/FeaturedHome.vue";
    import TeamHome from "@/components/TeamHome.vue";
    import Footer from "@/components/Footer.vue";

    import getMyFavouriteLanguage from "@/services/language.js";

    import home_en from "@/contents/home-pt.json";
    import home_es from "@/contents/home-pt.json";
    import home_fr from "@/contents/home-pt.json";
    import home_pt from "@/contents/home-pt.json";

    export default {
        components: {
            Header,
            // Slider,
            SliderClean,
            Mosaic,
            // HalfBanners,
            TreatmentsCarousel,
            // ProductsHome,
            FeaturedHome,
            TeamHome,
            Footer
        },
        data() {
            return {
                flagSelected: {},

                home: {},

                // productsCarousel: {
                //     infos: {
                //         type: "highlights",
                //         title: "Em destaque",
                //         short: "Os melhores produtos para os seus cuidados"
                //     },
                //     products: [
                //         {
                //             link: "#",
                //             img: "product-example.jpg",
                //             name: "Kit Banho Super Hidratante"
                //         },
                //         {
                //             link: "#",
                //             img: "product-example.jpg",
                //             name: "Kit Banho Super Hidratante"
                //         },
                //         {
                //             link: "#",
                //             img: "product-example.jpg",
                //             name: "Kit Banho Super Hidratante"
                //         },
                //         {
                //             link: "#",
                //             img: "product-example.jpg",
                //             name: "Kit Banho Super Hidratante"
                //         },
                //         {
                //             link: "#",
                //             img: "product-example.jpg",
                //             name: "Kit Banho Super Hidratante"
                //         },
                //         {
                //             link: "#",
                //             img: "product-example.jpg",
                //             name: "Kit Banho Super Hidratante"
                //         },
                //         {
                //             link: "#",
                //             img: "product-example.jpg",
                //             name: "Kit Banho Super Hidratante"
                //         },
                //         {
                //             link: "#",
                //             img: "product-example.jpg",
                //             name: "Kit Banho Super Hidratante"
                //         }
                //     ]
                // }
            }
        },

        // async mounted() {
            
        // },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            getMyLanguage(group, code) {
                return getMyFavouriteLanguage(group, code);
            },

            // goTop(){
            //     $('html, body').animate({scrollTop : 0},200);
            //     return false;
            // },

            languageContent(){
                if ( this.flagSelected === 'en' ){
                    this.home = home_en
                } else if ( this.flagSelected === 'es' ){
                    this.home = home_es
                } else if ( this.flagSelected === 'fr' ){
                    this.home = home_fr
                } else {
                    this.home = home_pt
                }
            },
        },

        created(){
            this.flagUrl()
            this.languageContent()
            // this.goTop()
        }
    };
</script>

<style src="./custom-home.scss" lang="scss" scoped />