<template>
    <section class="localization">
        <div id="como-chegar" class="anc"></div>
        
        <div class="container-fluid">
            <h2 class="title-primary"><span>Como chegar</span></h2>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3003.0464952508246!2d-8.56101998458075!3d41.177153079284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd2463919bd9a2bd%3A0xd71cac8518590794!2sAv.%20Dr.%20Domingos%20Gon%C3%A7alves%20de%20S%C3%A1%20434%2C%204435-213%20Rio%20Tinto!5e0!3m2!1spt-PT!2spt!4v1680014350345!5m2!1spt-PT!2spt" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </section>
</template>


<script>
    import getMyFavouriteLanguage from "@/services/language.js";

    export default{
        methods: {
            getMyLanguage(group, code){
                return getMyFavouriteLanguage(group, code)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/_elements.scss';

    .localization{
        // @include paddingSection;
        background: #eee;
        
        @media (max-width: 576px){
            padding-top: 35px;
            padding-bottom: 15px;
        }

        @media (min-width: 576px){
            padding-top: 50px;
            padding-bottom: 20px;
        }

        iframe{
            width: 100%;
            display: block;

            @media (max-width: 576px){
                height: 280px;
            }

            @media (min-width: 576px) and (max-width: 992px){
                height: 340px;
            }

            @media (min-width: 992px){
                height: 460px;
            }
        }
    }
</style>