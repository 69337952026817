<template>
    <div class="content-page featured-image">
        <Header />

        <div class="image-header">
            <h1 class="title-primary">
                <span>Cursos</span>
                <small>Descubra o seu potencial ilimitado e aprimore as suas competências com os nossos cursos especializados na Zenith Saúde.</small>
            </h1>

            <img src="/images/content/cursos-header.jpg">
        </div>
        
        
        <section class="cursos">
            <div class="container">

                <div class="row list">
                    
                    <!-- CURSOS MEDICINA ESTÉTICA -->
                    <div class="col-12" v-if="filterMedicinaEstetica.length">
                        <h2 class="title-primary title-left">
                            <span>Cursos de medicina estética</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/curso/' + course.slug" v-for="(course,index) in filterMedicinaEstetica" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + course.img">
                        </span>
                        <h2 class="title">{{course.name}}</h2>
                        <p>{{course.cta}}</p>
                    </a>
                    


                    <div class="bar-category" v-if="filterBelezaBemEstar.length"></div>                    

                    <!-- CURSOS BELEZA E BEM-ESTAR -->
                    <div class="col-12" v-if="filterBelezaBemEstar.length">
                        <h2 class="title-primary title-left">
                            <span>Cursos de beleza e bem-estar</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/curso/' + course.slug" v-for="(course,index) in filterBelezaBemEstar" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + course.img">
                        </span>
                        <h2 class="title">{{course.name}}</h2>
                        <p>{{course.cta}}</p>
                    </a>
                </div>
            </div>
        </section>

        <Footer dataSource="Página de cursos" />
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";
    
    import getMyFavouriteLanguage from "@/services/language.js";

    import courses_en from "@/contents/courses-pt.json"
    import courses_es from "@/contents/courses-pt.json"
    import courses_fr from "@/contents/courses-pt.json"
    import courses_pt from "@/contents/courses-pt.json"

    export default {
        components: {
            Header,
            Footer
        },
        
        data() {
            return {
                flagSelected: {},
            }
        },

        // async mounted() {

        // },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            getMyLanguage(group, code) {
                return getMyFavouriteLanguage(group, code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            languageContent(){
                if ( this.flagSelected === 'en' ){
                    this.courses = courses_en
                } else if ( this.flagSelected === 'es' ){
                    this.courses = courses_es
                } else if ( this.flagSelected === 'fr' ){
                    this.courses = courses_fr
                } else {
                    this.courses = courses_pt
                }
            }
        },

        computed: {
            filterMedicinaEstetica() {
                if (this.courses.length === 0) {
                    return [];
                }

                return this.courses.filter(course => course.category === 'Medicina estética');
            },

            filterBelezaBemEstar() {
                if (this.courses.length === 0) {
                    return [];
                }

                return this.courses.filter(course => course.tag === 'Beleza e bem-estar');
            },
        },

        created() {
            this.flagUrl()
            this.languageContent()
            this.goTop()

            document.title = "Cursos"
            document.head.querySelector("meta[name=description]").content = "Descubra o seu potencial ilimitado e aprimore as suas competências com os nossos cursos especializados na Zenith Saúde.";
        }
    };
</script>

<style src="@/assets/scss/_content.scss" lang="scss" scoped />
<style src="./custom-cursos.scss" lang="scss" scoped />