<template>
    <div class="content-page">
        <Header />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="breadcrumb">
                        <li><a :href="'/' + flagSelected + '/'"><span class="home"></span></a></li>
                        <li><a :href="'/' + flagSelected + '/' + filterTreatments[0].category_slug"><span>{{filterTreatments[0].category}}</span></a></li>
                        <li class="active"><span>{{filterTreatments[0].tag}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
        
        
        <section class="filter-tag">
            <div class="container">

                <div class="row list">
                    
                    <!-- FILTER TAG -->
                    <div class="col-12" v-if="filterTreatments.length">
                        <h2 class="title-primary title-left">
                            <span>{{filterTreatments[0].tag}}</span>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/tratamento/' + treatment.slug" v-for="(treatment,index) in filterTreatments" :key="index">
                        <span class="image">
                            <img :src="'/images/content/' + treatment.img">
                        </span>
                        <h2 class="title">{{treatment.name}}</h2>
                        <p>{{treatment.cta}}</p>
                    </a>

                </div>
            </div>
        </section>

        <Footer :dataSource="'Medicina estética > ' + filterTreatments[0].tag" />
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $

    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";
    
    import getMyFavouriteLanguage from "@/services/language.js";

    import treatments_en from "@/contents/treatments-pt.json"
    import treatments_es from "@/contents/treatments-pt.json"
    import treatments_fr from "@/contents/treatments-pt.json"
    import treatments_pt from "@/contents/treatments-pt.json"

    export default {
        components: {
            Header,
            Footer
        },
        
        data() {
            return {
                flagSelected: {}
            }
        },

        // async mounted() {

        // },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            getMyLanguage(group, code) {
                return getMyFavouriteLanguage(group, code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            languageContent(){
                if ( this.flagSelected === 'en' ){
                    this.treatments = treatments_en
                } else if ( this.flagSelected === 'es' ){
                    this.treatments = treatments_es
                } else if ( this.flagSelected === 'fr' ){
                    this.treatments = treatments_fr
                } else {
                    this.treatments = treatments_pt
                }
                
                // this.filterTreatments = this.treatments.filter(filterSlug => filterSlug.tag_slug == this.$route.params.slugTag)
            },
        },

        computed: {
            filterTreatments() {
                if (this.treatments.length === 0) {
                    return [];
                }

                return this.treatments.filter(treatment => treatment.tag_slug == this.$route.params.slugTag);
            }
        },

        created() {
            this.flagUrl()
            this.languageContent()
            this.goTop()

            document.title = this.filterTreatments[0].tag + " | Zenith Saúde"
            document.head.querySelector("meta[name=description]").content = "Experimente a medicina estética avançada no Zenith Saúde e descubra uma abordagem única para realçar sua beleza interior.";
        }
    };
</script>

<style src="@/assets/scss/_content.scss" lang="scss" scoped />
<style src="./custom-medicina-estetica.scss" lang="scss" scoped />