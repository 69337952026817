<template>
    <div class="content-page">
        <Header />

        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="breadcrumb">
                        <li><a :href="'/' + flagSelected + '/'"><span class="home"></span></a></li>
                        <li><a :href="'/' + flagSelected + '/' + details.category_slug"><span>{{details.category}}</span></a></li>
                        <li class="d-none d-md-flex"><a :href="'/' + flagSelected + '/' +  details.category_slug + '/' + details.tag_slug"><span>{{details.tag}}</span></a></li>
                        <li class="active"><span>{{details.name}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
        
        <section class="details-treatment">
            <div class="container">
                <div class="row mb-5">
                    <div class="col-12 col-lg-6 order-lg-2 mb-5 mb-lg-0 photo-treatment">
                        <div>
                            <img :src="'/images/content/' + details.img" :alt="details.name">
                        </div>
                    </div>

                    <div class="col-12 col-lg-6 order-lg-1 d-lg-flex align-items-center">
                        <div>
                            <a :href="'/' + flagSelected + '/tratamento/' + details.slug"><h1 class="title-primary title-left-sm"><span>{{details.name}}</span></h1></a>
                            <div v-html="details.text"></div>
                        </div>
                    </div>
                </div>

                <div class="row mb-3">
                    <div class="col-12">
                        <div class="accordion">
                            <div class="topic" v-for="(topics, indexTopic) in details.topics" :key="indexTopic">
                                <h6>{{topics.title}}</h6>
                                <div class="cont" v-html="topics.cont"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="others-treatments">
            <div class="container">
                <div class="row list" v-if="filterTag.length">
                    <div class="col-12">
                        <h2 class="title-primary">
                            <span>Outros tratamentos</span>
                            <small>{{details.tag}}</small>
                        </h2>
                    </div>

                    <a :href="'/' + flagSelected + '/tratamento/' + treatment.slug" v-for="(treatment,index) in filterTag" :key="index">
                        <span class="image">
                            <span>
                                <img :src="'/images/content/' + treatment.img">
                            </span>
                        </span>
                        <h2 class="title">{{treatment.name}}</h2>
                        <p>{{treatment.cta}}</p>
                    </a>
                </div>
            </div>
        </section>

        <Footer :dataSource="details.name" />
    </div>
</template>

<script>
    const $ = require('jquery')
    window.$ = $
    
    $(document).on("click", ".topic h6", function(){
        $('.topic').removeClass('active');
        $('.topic .cont').hide();
        $(this).parent().addClass('active');
        $('.topic.active .cont').show();
    });

    $(document).on("click", ".topic.active h6", function(){
        $('.topic .cont').hide();
        $('.topic').removeClass('active');
    });

    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";
    
    import getMyFavouriteLanguage from "@/services/language.js";

    import treatments_en from "@/contents/treatments-pt.json"
    import treatments_es from "@/contents/treatments-pt.json"
    import treatments_fr from "@/contents/treatments-pt.json"
    import treatments_pt from "@/contents/treatments-pt.json"

    export default {
        components: {
            Header,
            Footer
        },

        data() {
            return {
                flagSelected: {},
                
                details: []
            }
        },
    
        // async mounted(){
            
        // },

        methods: {
            flagUrl() {
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect;
            },

            getMyLanguage(group, code) {
                return getMyFavouriteLanguage(group, code);
            },

            goTop(){
                $('html, body').animate({scrollTop : 0},200);
                return false;
            },

            languageContent(){
                if ( this.flagSelected === 'en' ){
                    this.treatments = treatments_en
                } else if ( this.flagSelected === 'es' ){
                    this.treatments = treatments_es
                } else if ( this.flagSelected === 'fr' ){
                    this.treatments = treatments_fr
                } else {
                    this.treatments = treatments_pt
                }

                this.details = this.treatments.filter(filterSlug => filterSlug.slug == this.$route.params.slugTreatment)[0]
            },

            activeMenu(){
                setTimeout(() => {
                    if ( this.details.category_slug == "medicina-estetica" ){
                        $("header #nav .aesthetic-medicine").addClass("active")
                    }
                    
                    if ( this.details.category_slug == "beleza-e-bem-estar" ){
                        $("header #nav .beauty-and-wellness").addClass("active")
                    }
                }, 100);
            }
        },

        computed: {
            filterTag() {
                if (this.treatments.length === 0) {
                    return [];
                }

                return this.treatments.filter(treatment => treatment.tag === this.details.tag && treatment.slug != this.details.slug);
            },
        },

        created() {
            this.flagUrl()
            this.languageContent()
            this.activeMenu()
            this.goTop()

            document.title = this.details.name + " | Zenith Saúde"
            document.head.querySelector("meta[name=description]").content = this.details.cta;
        }
    }
</script>

<style src="./custom-detalhes-tratamento.scss" lang="scss" scoped />
<style src="@/assets/scss/_content.scss" lang="scss" scoped />

<style lang="scss">
    .accordion .topic .cont ul{
        li{
            list-style: disc;
        }
    }
</style>