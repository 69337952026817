<template>
    <section class="featured">
        <div class="container-fluid">
            <div class="row">
                <div class="col-10 col-lg-6 col-photo">
                    <div class="photo">
                        <img :src="'/images/content/' + dataSource.img" :alt="dataSource.name">
                    </div>
                </div>

                <div class="col-12 col-lg-6 col-product">
                    <div class="product">
                        <div class="content">
                            <h2>{{dataSource.name}}</h2>
                            <p>{{dataSource.cta}}</p>
                            <a :href="'/' + flagSelected + '/' + dataSource.slug" class="bt-secondary">Conheça</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
   export default {
        props: {
            dataSource: {}
        },

        data (){
            return {
                flagSelected: ""
            }
        },

        async mounted(){
            this.flagUrl()
        },

        methods: {
            flagUrl(){
                const flagSelect = String(window.location.pathname).split("/")[1];
                this.flagSelected = flagSelect
            },
        }
   }
</script>

<style lang="scss" scoped>
   @import '@/assets/scss/_colors.scss';

    .featured{
        @media (max-width: 576px){

        }

        @media (min-width: 576px) and (max-width: 992px){

        }

        @media (min-width: 992px){
            padding-bottom: 20px;
        }

        .col-photo{
            position: relative;

            &:after{
                content: '';
                display: block;
                background: url('~@/assets/images/layout/icon-zenith.svg') no-repeat center;
                background-size: 100% auto;
                position: absolute;
                z-index: 20;

                @media (max-width: 576px){

                }

                @media (min-width: 576px) and (max-width: 992px){

                }

                @media (min-width: 992px){
                    width: 60px;
                    height: 60px;
                    right: -15px;
                    bottom: -15px;
                }
            }

            .photo{
                display: block;
                overflow: hidden;
                width: 100%;
                padding-top: 56.25%;
                position: relative;
                z-index: 20;
    
                img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    height: auto;
                    transform: translate(-50%, -50%);
                }
            }
        }


        .product{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;

            &:after{
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                background: url("~@/assets/images/layout/zenith-flowers.svg") no-repeat right bottom $color-primary;
            }

            .content{
                position: relative;
                z-index: 30;

                @media (min-width: 992px){
                    max-width: 540px;
                }

                h2{
                    color: $color-white;

                    @media (max-width: 576px){
                        font-size: 26px;
                        line-height: 29px;
                        margin-bottom: 15px;
                    }
                    
                    @media (min-width: 576px) and (max-width: 992px){
                        font-size: 34px;
                        line-height: 36px;
                        margin-bottom: 20px;
                    }
                    
                    @media (min-width: 992px){
                        font-size: 42px;
                        line-height: 45px;
                        margin-bottom: 25px;
                    }
                }

                p{
                    color: rgba($color: $color-white, $alpha: 0.7);
                }

                .bt-secondary{
                    border-color: $color-white;
                    color: $color-white;
                    margin-top: 20px;
                }
            }

            @media (max-width: 992px){
                padding: 1.5rem;

                &:after{
                    width: 100%;
                    height: calc(100% + 100px);
                    margin-top: -100px;
                    background-size: 80px auto;
                }
            }

            @media (min-width: 992px){
                height: 100%;
                padding-left: 50px;
                margin-top: 20px;

                &:after{
                    width: 150%;
                    background-size: 160px auto;
                }
            }
        }
    }
</style>