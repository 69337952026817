<template>
   <div>
      <header>
         <div class="container-fluid">
            <div class="row">
               <div class="col-logo">
                  <router-link :to="{ name: 'Home' }" class="logo"></router-link>
               </div>

               <div class="col-menu">
                  <Menu :dataSource="schedule" />
               </div>

               <div class="col-language">
                  <!-- <SelectLanguage /> -->
                  <div class="open-menu" @click="menuMobile = true"></div>
               </div>
            </div>
         </div>
      </header>

      <div class="menuMobile" :class="{menuMobileOpened: menuMobile}">
         <div class="closeMenuMobile" @click="menuMobile = false"></div>
         <div class="menu-mobile">
            <div class="logo">
               <img src="~@/assets/images/layout/logo-zenith-saude.svg" alt="Zenith Saúde">
            </div>
            <Menu :dataSource="schedule" />
            <ContactUs />
         </div>
      </div>
   </div>
</template>


<script>
   const $ = require("jquery");
   window.$ = $;

   $(document).ready(function () {
      var animaHeader = $("body");
      $(window).scroll(function () {
         if ($(this).scrollTop() > 60) {
         animaHeader.addClass("headerFixed");
         } else {
         animaHeader.removeClass("headerFixed");
         }
      });
   });

   import Menu from "@/components/Menu"
   import ContactUs from "@/components/ContactUs"
   // import SelectLanguage from "@/components/SelectLanguage"

   export default {
      props: {
         dataSource: {},
      },

      components: {
         Menu,
         ContactUs,
         // SelectLanguage,
      },

      data (){
         return {
            menuMobile: false,
            schedule: ""
         }
      },

      created (){
         if ( this.dataSource != undefined ){
            this.schedule = false
         } else {
            this.schedule = true
         }
      }
   }
</script>

<style src="@/assets/scss/_header.scss" lang="scss" scoped />